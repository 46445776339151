import { BaseEntity } from 'processdelight-angular-components';
import { DependencyType } from './dependency-type.model';
import { DependencyValue } from './dependency-value.model';

export class Dependency extends BaseEntity {
  title?: string;
  taskId!: string;
  dependentTaskId?: string;
  dependencyTypeId?: string;
  dependencyType?: DependencyType;
  dependencyValues?: DependencyValue[];

  constructor(obj: Partial<Dependency>) {
    super(obj);
    Object.assign(this, obj);
  }
}