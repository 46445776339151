import { BaseEntity } from "processdelight-angular-components";

export class DependencyValue extends BaseEntity {
    value!: string;
    taskDependencyId!: string;

    constructor(obj: DependencyValue) {
        super(obj);
        Object.assign(this, obj);
    }
}
