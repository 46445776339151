import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { Status } from '../domain/models/status.model';
import { camelcaseKeys } from '../helpers/object.functions';

@Injectable({ providedIn: 'root' })
export class StatusApiService {
  apiBase = `${location.origin}/web`;

  constructor(private httpClient: HttpClient) {}

    /////////////////////////////////////////////////// GET ///////////////////////////////////////////////////
    public getStatusses(): Observable<Status[]> {
      return this.httpClient.get<Status[]>(`${this.apiBase}/status`)
        .pipe(
          map((statusses) =>
            statusses.map((s) => new Status(camelcaseKeys(s)))
          ),
          catchError((error) => {
            return throwError(error);
          })
        );
  }

  /////////////////////////////////////////////////// POST ///////////////////////////////////////////////////
  public addStatusses(statusses: Status[]): Observable<Status[]> {
    return this.httpClient.post<Status[]>(`${this.apiBase}/status`, statusses)
      .pipe(
        map((statusses) =>
          statusses.map((s) => new Status(camelcaseKeys(s)))
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////////// PATCH ///////////////////////////////////////////////////
  public updateStatusses(statusses: Status[]): Observable<Status[]> {
    return this.httpClient.patch<Status[]>(`${this.apiBase}/status`, statusses)
      .pipe(
        map((statusses) =>
          statusses.map((s) => new Status(camelcaseKeys(s)))
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////////// DELETE ///////////////////////////////////////////////////

  // soft delete so set IsDeleted to true
  public removeStatus(id: string): Observable<string> {
    return this.httpClient.delete(`${this.apiBase}/status/${id}`, { responseType: 'text' })
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
