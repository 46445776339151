import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, mergeMap, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import { addBatch, addBatchResolved, deleteBatch, deleteBatchResolved, getBatches, getBatchesResolved, updateBatch, updateBatchResolved } from './batch.actions';
import { BatchApiService } from '../../services/batch-api.service';

@Injectable({providedIn: 'root'})
export class BatchEffects {
  constructor(
    private actions$: Actions,
    private batchApiService: BatchApiService
  ) {
  }

  getBatches = createEffect(() =>
    this.actions$.pipe(
      ofType(getBatches),
      mergeMap(({ orderBy, direction, filters, pageSize, page, resetPaging, callback, error }) =>
        this.batchApiService.getBatches(orderBy, direction, filters, pageSize, page)
          .pipe(
            switchMap(({result, pagingCookie, totalRecordCount}) =>
              of(
                getBatchesResolved({
                  result,
                  pagingCookie,
                  totalRecordCount,
                  resetPaging,
                })
              )
            ),
            tap(() => (callback ? callback() : undefined)),
            catchError((e) => {
              if (error) error(e);
              return [];
            })
          )
      )
    )
  );

  addBatch = createEffect(() =>
    this.actions$.pipe(
      ofType(addBatch),
      mergeMap(({ batch, callback, error }) =>
        this.batchApiService.addBatch(batch).pipe(
          tap((batches) => (callback ? callback(batches) : undefined)),
          switchMap((addedBatch) => of(addBatchResolved({ addedBatch }))),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  updateBatch = createEffect(() =>
    this.actions$.pipe(
      ofType(updateBatch),
      switchMap(({ batch, callback, error }) =>
        this.batchApiService.updateBatch(batch).pipe(
          tap((batch) => (callback ? callback(batch): undefined)),
          switchMap((batch) => {
            return of(updateBatchResolved({batch}));
          }),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  removeBatch = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteBatch),
      switchMap(({ id, callback, error }) =>
      this.batchApiService.removeBatch(id).pipe(
        tap((batchId) => (callback ? callback(batchId): undefined)),
        switchMap((batchId) => {
          return of(deleteBatchResolved({id: batchId}));
        }),
        catchError((e) => {
          if (error) error(e);
          return [];
        })
      )
      )
    )
  );
}
