import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { TaskType } from '../domain/models/task/task-type.model';
import { RegistrationType } from '../domain/models/task/registration-type.model';
import { DependencyType } from '../domain/models/task/dependency-type.model';
import { Skill } from '../domain/models/skill.model';
import { ActionType } from '../domain/models/task/decision/action-type.model';
import { LogType } from '../domain/models/task/log-type.model';
import { ApprovalType } from '../domain/models/task/decision/approval-type.model';
import { ChannelType, Day, Frequency, Month, Ranking, TriggerType } from 'processdelight-angular-components';
import { MetadataParameter } from '../domain/models/dms/metadata-parameter.model';
import { ResourceThing } from '../domain/models/resource/resource-thing.model';
import { ResourceUser } from '../domain/models/resource/resource-user.model';
import { StatusType } from '../domain/models/status-type.model';

@Injectable({ providedIn: 'root' })
export class TypesApiService {
  apiBase = `${location.origin}/web/type`;

  constructor(private httpClient: HttpClient) {}

  /////////////////////////////////////////////////// GET ///////////////////////////////////////////////////

  public getProjects(): Observable<{ id?: string, projectName: string, projectId?: string }[]> {
    const url = `${this.apiBase}/project`;
    return this.httpClient.get<{ id?: string, projectName: string, projectId?: string }[]>(url)
      .pipe(catchError((error) => throwError(() => error)));
  }

  public getSkills(): Observable<Skill[]> {
    const url = `${this.apiBase}/skill`;
    return this.httpClient.get<Skill[]>(url)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getTaskTypes(): Observable<TaskType[]> {
    const url = `${this.apiBase}/task`;
    return this.httpClient.get<TaskType[]>(url)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getRegistrationTypes(): Observable<RegistrationType[]> {
    const url = `${this.apiBase}/registration`;
    return this.httpClient.get<RegistrationType[]>(url)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getDependencyTypes(): Observable<DependencyType[]> {
    const url = `${this.apiBase}/dependency`;
    return this.httpClient.get<DependencyType[]>(url)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getActionTypes(): Observable<ActionType[]> {
    const url = `${this.apiBase}/action`;
    return this.httpClient.get<ActionType[]>(url)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getLogTypes(): Observable<LogType[]> {
    const url = `${this.apiBase}/log`;
    return this.httpClient.get<LogType[]>(url)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getApprovalTypes(): Observable<ApprovalType[]> {
    const url = `${this.apiBase}/approval`;
    return this.httpClient.get<ApprovalType[]>(url)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getTriggerTypes(): Observable<TriggerType[]> {
    const url = `${this.apiBase}/trigger`;
    return this.httpClient.get<TriggerType[]>(url)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getChannelTypes(): Observable<ChannelType[]> {
    const url = `${this.apiBase}/channel`;
    return this.httpClient.get<ChannelType[]>(url)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getStatusTypes(): Observable<StatusType[]> {
    const url = `${this.apiBase}/status`;
    return this.httpClient.get<StatusType[]>(url)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getMetadataParameters(): Observable<MetadataParameter[]> {
    const url = `${this.apiBase}/metadata-parameter`;
    return this.httpClient.get<MetadataParameter[]>(url)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getDays(): Observable<Day[]> {
    const url = `${this.apiBase}/day`;
    return this.httpClient.get<Day[]>(url)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getMonths(): Observable<Month[]> {
    const url = `${this.apiBase}/month`;
    return this.httpClient.get<Month[]>(url)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getRankings(): Observable<Ranking[]> {
    const url = `${this.apiBase}/ranking`;
    return this.httpClient.get<Ranking[]>(url)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getFrequencies(): Observable<Frequency[]> {
    const url = `${this.apiBase}/frequency`;
    return this.httpClient.get<Frequency[]>(url)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getResourceThings(): Observable<ResourceThing[]> {
    const url = `${this.apiBase}/resource-thing`;
    return this.httpClient.get<ResourceThing[]>(url)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getResourceUsers(): Observable<ResourceUser[]> {
    const url = `${this.apiBase}/resource-user`;
    return this.httpClient.get<ResourceUser[]>(url)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
