import { BaseEntity } from 'processdelight-angular-components';
import { DateTime } from 'luxon';

export class Metadata extends BaseEntity {
  value?: string;
  numberValue?: number;
  dateTimeValue?: DateTime;
  groupUserValueId?: string;
  metadataParameterId?: string;
  itemId!: string;

  constructor(obj: Partial<Metadata>) {
    super(obj);
    Object.assign(this, obj);
  }
}