import { AvailableStatus } from "../enums/available-status.enum";

export class AvailableUser {
  userId!: string;
  status!: AvailableStatus;
  reason!: string;

  constructor(obj: Partial<AvailableUser>) {
    Object.assign(this, obj);
  }
}