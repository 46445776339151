import { BaseEntity } from 'processdelight-angular-components';
import { DMSAppItem } from './dms-app-item.model';
import { Metadata } from './metadata.model';

export class DMSItem extends BaseEntity {
  title?: string;
  fileLocation = '';
  sharepointId = '';
  libraryId?: string;
  appItems?: DMSAppItem[];
  metadata?: Metadata[];

  constructor(obj: Partial<DMSItem>) {
    super(obj);
    Object.assign(this, obj);
  }
}
