import { ApplicationNotification, BaseEntity } from "processdelight-angular-components";
import { DateTime } from "luxon";
import { RegistrationType } from "./registration-type.model";
import { Project } from "./project.model";
import { Status } from "../status.model";
import { Priority } from "./priority.model";
import { Skill } from "../skill.model";
import { TaskType } from "./task-type.model";
import { Log } from "./log.model";
import { Checklist } from "./checklist.model";
import { Dependency } from "./dependency.model";
import { DependencyValue } from "./dependency-value.model";
import { ApprovalType } from "./decision/approval-type.model";
import { DMSItem } from "../dms/dms-item.model";
import { ApprovalFlow } from "./decision/approval-flow.model";
import { ResourceFunction } from "../resource/resource-function.model";
import { Attendee } from "./attendee.model";
import { GroupUserEntity } from "../groupUserEntity.model";

export class Task extends BaseEntity {
    title!: string;
    number?: string;
    description?: string;
    startTime?: DateTime;
    endTime?: DateTime;
    deadline?: DateTime;
    actualStartTime?: DateTime;
    actualEndTime?: DateTime;
    progress?: number;
    progressRegistrationTypeId?: string;
    progressRegistrationType?: RegistrationType;
    parentTaskId?: string;
    parentTask?: Task;
    projectId?: string;
    project?: Project;
    statusId?: string;
    status?: Status;
    priorityId?: string;
    priority?: Priority;
    skillId?: string;
    skill?: Skill;
    typeId?: string;
    type?: TaskType;
    isTaskTemplate!: boolean;
    subTasks?: Task[];
    logs?: Log[];
    checklists?: Checklist[];
    dependencies?: Dependency[];
    dependencyValues?: DependencyValue[];
    owners?: GroupUserEntity[];
    estimatedTime?: number;
    completedTime?: number;
    isDeleted!: boolean;
    closeTaskWhenSubtasksDone?: boolean;
    approvalTypeId?: string;
    approvalType?: ApprovalType;
    approvalLinkId?: string;
    approvalLink?: DMSItem;
    documents?: DMSItem[];
    approvalFlows?: ApprovalFlow[];
    followers?: GroupUserEntity[];
    resourceUsers?: GroupUserEntity[];
    resourceFunctions?: ResourceFunction[];

    // meeting task
    location?: string; 
    linkTeamsMeeting?: boolean;
    meetingId?: string;
    attendees?: Attendee[]; 

    isLoadedFromTemplate = false; 
    taskTemplateSourceId?: string;
    notifications?: ApplicationNotification[]; //---> wordt dit ergens gebruikt? TODO

    constructor(obj: Partial<Task>) {
        super(obj);
        Object.assign(this, obj);
    }
}
