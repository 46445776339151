import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Settings } from 'luxon';
import {
  ActionBarComponent,
  ActionBarService,
  BreadcrumbsComponent,
  ContextMenuAction,
  DashboardComponent,
  GeneralLayoutComponent,
  LoaderComponent,
  SideBarAction,
  SideBarComponent,
  TopBarComponent,
  TopLayoutComponent,
  TranslationFile,
  TranslationService
} from 'processdelight-angular-components';
import { Subject } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CoreModule } from './core.module';
import { licenseInfo$ } from './core/data/data.observables';
import { initialLoadSubject } from './core/services/startup.service';
import { ErrorDialogService } from './core/helpers/error-dialog.service';
import { ErrorHandlingService } from './core/helpers/error-handling.service';

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    GeneralLayoutComponent,
    DashboardComponent,
    TopBarComponent,
    SideBarComponent,
    BreadcrumbsComponent,
    CoreModule,
    RouterModule,
    ActionBarComponent,
    LoaderComponent,
    TopLayoutComponent,
  ]
})
export class AppComponent implements OnInit, OnDestroy {
  static title = 'IshtarTasks';
  static dateFormat = 'dd/MM/yyyy';
  static sharepointLibraryname = 'Ishtar.Tasks! Documents';

  title = AppComponent.title;

  orgLogo$ = licenseInfo$.pipe(map((o) => o?.logo));
  appLogo = environment.cdnAppIconUrl.replace('color', 'blue');

  navColor$ = licenseInfo$.pipe(map((u) => u?.navColor));
  navContrast$ = licenseInfo$.pipe(map((u) => u?.navContrast));
  userName$ = licenseInfo$.pipe(map((u) => u?.name));
  userPhoto$ = licenseInfo$.pipe(map((u) => u?.photo));

  isAdmin$ = licenseInfo$.pipe(
    map((l) =>
      l?.licenses.some((license) => {
        return (
          license.productName === 'Ishtar.Tasks' && license.isAdmin === true
        );
      })
    )
  );

  buttonActions = this.actionbar.buttonActions;
  navActions: SideBarAction[] = [];
  iconActions: ContextMenuAction<unknown>[] = [];

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private actionbar: ActionBarService,
    private translationService: TranslationService,
    private errorService: ErrorHandlingService,
    private errorDialogService: ErrorDialogService
  ) { }

  ngOnInit(): void {
    this.errorService.getErrorStream().subscribe((errors) => {
      this.errorDialogService.openErrorDialog(errors);
    });

    Settings.defaultLocale = 'nl-BE';

    this.initializeLayout();

    initialLoadSubject.pipe(
      takeUntil(this.destroy$),
      take(1),
      tap(() => {
        this.navActions = this.getSidebarButtons(this.translationService.translations);
      }),
      switchMap(() => this.isAdmin$.pipe(takeUntil(this.destroy$))),
      tap((isAdmin) => {
        if (isAdmin) {
          this.iconActions.push(this.getAdminSettingsButton(this.translationService.translations));
        }
      }))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initializeLayout(): void {
    const params = new URLSearchParams(location.search);

    if (params.has('appColor'))
      this.appLogo = environment.cdnAppIconUrl.replace(
        'color',
        params.get('appColor')!
      );

    const tabIconLink = document.querySelector(
      "link[rel~='icon']"
    ) as HTMLLinkElement;

    if (tabIconLink) tabIconLink.href = this.appLogo;
  }

  private getSidebarButtons(translations: TranslationFile): SideBarAction[] {
    return [
      new SideBarAction({
        title: translations['home'],
        icon: 'home',
        iconOutline: true,
        route: ['tasks'],
      }),
      new SideBarAction({
        title: translations['kanban'],
        icon: 'view_kanban',
        iconOutline: true,
        route: ['kanban'],
      }),
    ];
  }

  private getAdminSettingsButton(translations: TranslationFile): ContextMenuAction<unknown> {
    return new ContextMenuAction<unknown>({
      id: 'settings',
      label: translations.settings,
      icon: 'settings',
      iconOutline: true,
      action: () => this.router.navigate(['settings']),
    })
  }
}
