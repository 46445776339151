import { BaseEntity } from 'processdelight-angular-components';

export class Checklist extends BaseEntity {
  taskId!: string;
  value?: string;
  isChecked? = false;
  position = 0;

  constructor(obj: Partial<Checklist>) {
    super(obj);
    Object.assign(this, obj);
  }
}
