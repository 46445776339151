import { Action, createReducer, on } from '@ngrx/store';
import {
  addDependenciesResolved,
  addDependencyValuesResolved,
  removeDependenciesResolved,
  removeDependencyValuesResolved,
  updateDependenciesResolved,
  updateDependencyValuesResolved,
} from './dependency.actions';
import { AppState } from '../../../app.reducer';
import { DependencyValue } from '../../domain/models/task/dependency-value.model';
import { Dependency } from '../../domain/models/task/dependency.model';

export const featureSlice = 'dependency';

export interface State {
  dependencies: Dependency[];
  dependencyValues: DependencyValue[];
}

const defaultState: State = {
  dependencies: [],
  dependencyValues: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return dependencyReducer(state, action);
}

export const initialState: State = defaultState;

export const dependencyReducer = createReducer(
  initialState,
  on(removeDependenciesResolved, (state, { ids }) => ({
    ...state,
    dependencies: state.dependencies.filter(
      (s) => !ids.includes(s.id!)
    ),
  })),
  on(updateDependenciesResolved, (state, { updatedDependencies }) => ({
    ...state,
    dependencies: state.dependencies.map(
      (s) =>
        updatedDependencies.find(
          (u) => s.id === u.id
        ) ?? s
    ),
  })),
  on(addDependenciesResolved, (state, { addedDependencies }) => ({
    ...state,
    dependencies: addedDependencies.concat([...state.dependencies]),
  })),
  on(removeDependencyValuesResolved, (state, { ids }) => ({
    ...state,
    dependencyValues: state.dependencyValues?.filter(
      (s) =>
        !ids.includes(s.id || '')
    ),
  })),
  on(updateDependencyValuesResolved, (state, { updatedDependencyValues }) => ({
    ...state,
    dependencyValues: state.dependencyValues?.map(
      (s) =>
        updatedDependencyValues.find(
          (u) => s.id === u.id
        ) ?? s
    ),
  })),
  on(addDependencyValuesResolved, (state, { addedDependencyValues }) => ({
    ...state,
    dependencyValues: addedDependencyValues.concat([...state.dependencyValues]),
  }))
);

export const dependencyState = (state: AppState) =>
  state.coreFeature.dependency;
