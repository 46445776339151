import { BaseEntity } from "processdelight-angular-components";
import { StatusType } from "./status-type.model";

export class Status extends BaseEntity {
  status!: string;
  color?: string;
  isFinalState?: boolean;
  inShortMenu?: boolean;
  isDeleted?: boolean;
  sortNumber?: number;
  types?: StatusType[];

  constructor(obj: Partial<Status>) {
    super(obj);
    Object.assign(this, obj);
  }
}
