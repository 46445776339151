import {
  ChannelType,
  Day,
  Frequency,
  GroupUser,
  Month,
  Ranking,
  TriggerType,
} from 'processdelight-angular-components';
import { BehaviorSubject } from 'rxjs';
import { ApprovalType } from '../domain/models/task/decision/approval-type.model';
import { DependencyType } from '../domain/models/task/dependency-type.model';
import { ProjectHolder } from '../domain/models/task/project.model';
import { RegistrationType } from '../domain/models/task/registration-type.model';
import { Task } from '../domain/models/task/task.model';
import { LogType } from '../domain/models/task/log-type.model';
import { TaskType } from '../domain/models/task/task-type.model';
import { License } from '../domain/models/user/license.model';
import { LicenseInfo } from '../domain/models/user/license-info.model';
import { ActionType } from '../domain/models/task/decision/action-type.model';
import { Skill } from '../domain/models/skill.model';
import { ResourceThing } from '../domain/models/resource/resource-thing.model';
import { ResourceUser } from '../domain/models/resource/resource-user.model';
import { MetadataParameter } from '../domain/models/dms/metadata-parameter.model';
import { StatusType } from '../domain/models/status-type.model';

export const search$ = new BehaviorSubject<string>('');
export const licenseInfo$ = new BehaviorSubject<LicenseInfo | undefined>(undefined);
export const license$ = new BehaviorSubject<License | undefined>(undefined);
export const users$ = new BehaviorSubject<GroupUser[] | undefined>(undefined);
export const groups$ = new BehaviorSubject<GroupUser[] | undefined>(undefined);
export const projects$ = new BehaviorSubject<ProjectHolder[] | undefined>(undefined);
export const skills$ = new BehaviorSubject<Skill[] | undefined>(undefined);
export const taskTypes$ = new BehaviorSubject<TaskType[] | undefined>(undefined);
export const registrationTypes$ = new BehaviorSubject<RegistrationType[] | undefined>(undefined);
export const actionTypes$ = new BehaviorSubject<ActionType[] | undefined>(undefined);
export const tasks$ = new BehaviorSubject<Task[] | undefined>(undefined);
export const dependencyTypes$ = new BehaviorSubject<DependencyType[] | undefined>(undefined);
export const logTypes$ = new BehaviorSubject<LogType[] | undefined>(undefined);
export const approvalTypes$ = new BehaviorSubject<ApprovalType[] | undefined>(undefined);
export const frequencies$ = new BehaviorSubject<Frequency[] | undefined>(undefined);
export const days$ = new BehaviorSubject<Day[] | undefined>(undefined);
export const months$ = new BehaviorSubject<Month[] | undefined>(undefined);
export const rankings$ = new BehaviorSubject<Ranking[] | undefined>(undefined);
export const triggerTypes$ = new BehaviorSubject<TriggerType[] | undefined>(undefined);
export const channelTypes$ = new BehaviorSubject<ChannelType[] | undefined>(undefined);
export const statusTypes$ = new BehaviorSubject<StatusType[] | undefined>(undefined);
export const metadataParameters$ = new BehaviorSubject<MetadataParameter[] | undefined>(undefined);
export const resourceThings$ = new BehaviorSubject<ResourceThing[] | undefined>(undefined);
export const resourceUsers$ = new BehaviorSubject<ResourceUser[] | undefined>(undefined);
