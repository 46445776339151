import { BaseEntity } from 'processdelight-angular-components';

export class Log extends BaseEntity {
  title!: string;
  taskId?: string;
  logTypeId?: string;
  userId?: string;
  description?: string;

  constructor(obj: Partial<Log>) {
    super(obj);
    Object.assign(this, obj);
  }
}
