import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FunctionsService } from './functions.service';
import { Observable, catchError, map, throwError } from 'rxjs';
import { ApplicationNotification, Channel, CompositionGroup, GroupUser, Recurrency, TriggerType } from 'processdelight-angular-components';
import { appName } from './startup.service';
import { RecurrencyDay } from 'processdelight-angular-components/lib/notification-component/domain/recurrency-day.model';
import { RecurrencyMonth } from 'processdelight-angular-components/lib/notification-component/domain/recurrency-month.model';
import { camelcaseKeys } from '../helpers/object.functions';

@Injectable({ providedIn: 'root' })
export class NotificationApiService {
  apiBase = `${location.origin}/web`;

  constructor(
    private httpClient: HttpClient,
    private functionsService: FunctionsService
  ) {}

  /////////////////////////////////////////////////// GET ///////////////////////////////////////////////////
  public getApplicationNotificationById(
    applicationNotificationId: string
  ): Observable<ApplicationNotification> {
    return this.httpClient.get<ApplicationNotification>(`${this.apiBase}/application-notification/${applicationNotificationId}`)
      .pipe(
        map(
          (notification) => new ApplicationNotification(camelcaseKeys(notification))
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getApplicationNotifications(itemId: string): Observable<ApplicationNotification[]> {
    return this.httpClient.get<ApplicationNotification[]>(`${this.apiBase}/application-notification/${appName}/${itemId}`)
      .pipe(
        map((notifications) =>
          notifications.map(
            (n) => new ApplicationNotification(camelcaseKeys(n))
          )
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getApplicationNotificationForGridById(applicationNotificationId: string): Observable<ApplicationNotification> {
    return this.httpClient.get<ApplicationNotification>(`${this.apiBase}/application-notification/${applicationNotificationId}/grid`)
      .pipe(
        map(
          (n) => new ApplicationNotification(camelcaseKeys(n))
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////////// POST ///////////////////////////////////////////////////
  public createApplicationNotification(
    application: string | undefined,
    name: string | undefined,
    tableName: string | undefined,
    recordIds: string | undefined,
    receivers: GroupUser[] | undefined,
    channels: Channel[] | undefined,
    compositionGroups: CompositionGroup[] | undefined,
    recurrencyDays: RecurrencyDay[] | undefined,
    recurrencyMonths: RecurrencyMonth[] | undefined,
    recurrency: Recurrency | undefined,
    type: 'InstantMessaging' | 'Notification',
    triggerType: TriggerType | undefined,
    sendInstantMessage: boolean
  ): Observable<ApplicationNotification> {
    const body = {
      application: application,
      name: name,
      tableName: tableName,
      recordIds: recordIds,
      receivers: receivers,
      channels: channels,
      compositionGroups: compositionGroups,
      recurrencyDays: recurrencyDays,
      recurrencyMonths: recurrencyMonths,
      recurrency: recurrency,
      type: type,
      triggerType: triggerType,
      sendInstantMessage: sendInstantMessage
    };

    return this.httpClient.post<ApplicationNotification>(`${this.apiBase}/application-notification`, body)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  
  /////////////////////////////////////////////////// PATCH ///////////////////////////////////////////////////
  public updateApplicationNotification(
    ishtarApplicationNotificationId: string | undefined,
    application: string | undefined,
    name: string | undefined,
    tableName: string | undefined,
    recordIds: string | undefined,
    receivers: GroupUser[] | undefined,
    channels: Channel[] | undefined,
    compositionGroups: CompositionGroup[] | undefined,
    recurrencyDays: RecurrencyDay[] | undefined,
    recurrencyMonths: RecurrencyMonth[] | undefined,
    recurrency: Recurrency | undefined,
    type: 'InstantMessaging' | 'Notification',
    triggerType: TriggerType | undefined,
    sendInstantMessage: boolean
  ): Observable<ApplicationNotification> {
    const body = {
      ishtarApplicationNotificationId: ishtarApplicationNotificationId,
      application: application,
      name: name,
      tableName: tableName,
      recordIds: recordIds,
      receivers: receivers,
      channels: channels,
      compositionGroups: compositionGroups,
      recurrencyDays: recurrencyDays,
      recurrencyMonths: recurrencyMonths,
      recurrency: recurrency,
      type: type,
      triggerType: triggerType,
      sendInstantMessage: sendInstantMessage
    };

    return this.httpClient.patch<ApplicationNotification>(`${this.apiBase}/application-notification`, body)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////////// DELETE ///////////////////////////////////////////////////
  public removeApplicationNotification(applicationNotificationId: string): Observable<string[]> {
    return this.httpClient.delete<string[]>(`${this.apiBase}/application-notification/${applicationNotificationId}`)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
