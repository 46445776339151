import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { DMSItem } from '../domain/models/dms/dms-item.model';
import { Metadata } from '../domain/models/dms/metadata.model';
import { camelcaseKeys } from '../helpers/object.functions';

@Injectable({ providedIn: 'root' })
export class TaskDocumentsApiService {
  apiBase = `${location.origin}/web`;

  constructor(private httpClient: HttpClient) {}

  /////////////////////////////////////////////////// GET ///////////////////////////////////////////////////
  public getTaskDocuments(taskId: string): Observable<DMSItem[]> {
    return this.httpClient.get<DMSItem[]>(`${this.apiBase}/task/${taskId}/document`)
      .pipe(
        map((taskDocuments) => taskDocuments.map(
          (item) => new DMSItem(camelcaseKeys(item))
        )),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////////// CREATE ///////////////////////////////////////////////////
  public createTaskDocuments(documents: { taskId: string; filename: string; fileLocation: string; sharepointId: string; }[]): Observable<{ createdItems: DMSItem[]; updatedMetadata: Metadata[]; }> {
    return this.httpClient.post<{ createdItems: DMSItem[]; updatedMetadata: Metadata[]; }>(`${this.apiBase}/task/document`, documents)
      .pipe(
        map((result) => ({
          createdItems: result.createdItems.map(
            (item) => new DMSItem(camelcaseKeys(item))
          ),
          updatedMetadata: result.updatedMetadata.map(
            (item) => new Metadata(camelcaseKeys(item))
          ),
        })),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////////// DELETE ///////////////////////////////////////////////////
  public removeTaskDocuments(ids: string[]): Observable<string[]> {
    return this.httpClient.delete<string[]>(`${this.apiBase}/task/document`, { body: ids });
  }
}
