import { createAction, props } from '@ngrx/store';
import { Batch } from '../../domain/models/task/batch.model';
import { Filter } from 'processdelight-angular-components';

export const getBatches = createAction(
    '[Batch] Get batches',
    props<{
        orderBy: string;
        direction: string;
        filters: Filter[];
        pageSize: number;
        page: number;
        resetPaging?: boolean;
        callback?: () => void;
        error?: (e: any) => void;
    }>()
);

export const getBatchesResolved = createAction(
    '[Batch] Get Batches Resolved',
    props<{
        result: Batch[];
        pagingCookie: string;
        totalRecordCount: number;
        resetPaging?: boolean;
     }>()
);

export const addBatch = createAction(
    '[Batch] Add Batch',
    props<{
        batch: Batch;
        callback?: (batch : Batch) => void;
        error?: (e: any) => void;
    }>()
);

export const addBatchResolved = createAction(
    '[Batch] Add Batch Resolved',
    props<{
        addedBatch: Batch;
    }>()
);

export const updateBatch = createAction(
    '[Batch] Update Batch',
    props<{
        batch: Batch;
        callback?: (batch : Batch) => void;
        error?: (e: any) => void;
    }>()
);

export const updateBatchResolved = createAction(
    '[Batch] Update Batch Resolved',
    props<{batch: Batch }>()
  );

export const deleteBatch = createAction(
    '[Batch] Delete Batch',
    props<{
        id: string;
        callback?: (deletedBatchId: string) => void;
        error?: (e: any) => void;
    }>()
);

export const deleteBatchResolved = createAction(
    '[Batch] Delete Batch Resolved',
    props<{
        id: string;
    }>()
);
