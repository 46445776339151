import { createAction, props } from '@ngrx/store';
import { ActionBase } from '../action-base.interface';
import { ApplicationNotification, Channel, CompositionGroup, GroupUser, Recurrency, TriggerType } from 'processdelight-angular-components';
import { RecurrencyDay } from 'processdelight-angular-components/lib/notification-component/domain/recurrency-day.model';
import { RecurrencyMonth } from 'processdelight-angular-components/lib/notification-component/domain/recurrency-month.model';

export const getApplicationNotifications = createAction(
  '[ApplicationNotification] Get applicationNotifications',
  props<{
    itemId: string;
    callback?: (applicationNotifications: ApplicationNotification[]) => void;
    errorCallback?: (error: any) => void;
  }>()
);

export const getApplicationNotificationsResolved = createAction(
  '[ApplicationNotification] Get applicationNotifications resolved',
  props<{
    result: ApplicationNotification[];
  }>()
);
export const updateApplicationNotification = createAction(
  '[ApplicationNotification] Update applicationNotification',
  props<{
    ishtarApplicationNotificationId: string | undefined;
    application: string | undefined;
    name: string | undefined;
    tableName: string | undefined;
    recordIds: string | undefined;
    receivers: GroupUser[] | undefined;
    channels: Channel[] | undefined;
    compositionGroups: CompositionGroup[] | undefined;
    recurrencyDays: RecurrencyDay[] | undefined;
    recurrencyMonths: RecurrencyMonth[] | undefined;
    recurrency: Recurrency | undefined;
    triggerType: TriggerType | undefined;
    sendInstantMessage: boolean;
    callback?: (applicationNotification: ApplicationNotification) => void;
    error?: (error: any) => void;
  }>()
);

export const updateApplicationNotificationResolved = createAction(
  '[ApplicationNotification] Update applicationNotification resolved',
  props<{ applicationNotification: ApplicationNotification }>()
);

export const removeApplicationNotification = createAction(
  '[ApplicationNotification] Remove applicationNotification',
  props<
    {
      id: string;
    } & ActionBase<string[]>
  >()
);

export const removeApplicationNotificationResolved = createAction(
  '[ApplicationNotification] Remove applicationNotification resolved',
  props<{ ids: string[] }>()
);

export const addApplicationNotification = createAction(
  '[ApplicationNotification] Add applicationNotification',
  props<{
    application: string | undefined;
    name: string | undefined;
    tableName: string | undefined;
    recordIds: string | undefined;
    receivers: GroupUser[] | undefined;
    channels: Channel[] | undefined;
    compositionGroups: CompositionGroup[] | undefined;
    recurrencyDays: RecurrencyDay[] | undefined;
    recurrencyMonths: RecurrencyMonth[] | undefined;
    recurrency: Recurrency | undefined;
    triggerType: TriggerType | undefined;
    sendInstantMessage: boolean;
    callback?: (applicationNotification: ApplicationNotification) => void;
    error?: (error: any) => void; 
  }>()
);

export const addApplicationNotificationResolved = createAction(
  '[ApplicationNotification] Add applicationNotification resolved',
  props<{ addedApplicationNotification: ApplicationNotification }>()
);
