import * as fromTask from './task/task.reducer';
import * as fromTime from './time/time.reducer';
import * as fromDependency from './dependency/dependency.reducer';
import * as fromChecklist from './checklist/checklist.reducer';
import * as fromBatch from './batch/batch.reducer';
import * as fromApproval from './approval-flow/approval.reducer';
import * as fromApplicationNotification from './applicationNotification/applicationNotification.reducer';

import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { TaskEffects } from './task/task.effects';
import { DependencyEffects } from './dependency/dependency.effects';
import { ChecklistEffects } from './checklist/checklist.effects';
import { BatchEffects } from './batch/batch.effects';
import { ApprovalFlowEffects } from './approval-flow/approval.effects';
import { ApplicationNotificationEffects } from './applicationNotification/applicationNotification.effect';

export const featureKey = 'coreFeature';

export interface CoreState {
  [fromTask.featureSlice]: fromTask.State;
  [fromTime.featureSlice]: fromTime.State;
  [fromDependency.featureSlice]: fromDependency.State;
  [fromChecklist.featureSlice]: fromChecklist.State;
  [fromBatch.featureSlice]: fromBatch.State;
  [fromApproval.featureSlice]: fromApproval.State;
  [fromApplicationNotification.featureSlice]: fromApplicationNotification.State;
}

export const reducers = new InjectionToken<ActionReducerMap<CoreState>>(
  featureKey,
  {
    factory: () => ({
      [fromTask.featureSlice]: fromTask.Reducer,
      [fromTime.featureSlice]: fromTime.Reducer,
      [fromDependency.featureSlice]: fromDependency.Reducer,
      [fromChecklist.featureSlice]: fromChecklist.Reducer,
      [fromBatch.featureSlice]: fromBatch.Reducer,
      [fromApproval.featureSlice]: fromApproval.Reducer,
      [fromApplicationNotification.featureSlice]: fromApplicationNotification.Reducer,
    }),
  }
);
export const effects = [
  TaskEffects,
  DependencyEffects,
  ChecklistEffects,
  BatchEffects,
  ApprovalFlowEffects,
  ApplicationNotificationEffects,
];
