import { BaseEntity } from "processdelight-angular-components";
import { DateTime } from "luxon";

export class Project extends BaseEntity {
    projectName!: string;
    projectId?: string;
    parentId?: string;
    clientId?: string;
    statusId?: string;
    description?: string;
    preStudy?: boolean;
    calculateProgress?: boolean;
    isTemplate?: boolean;
    startDate?: DateTime;
    deadline?: DateTime;
    projectTypeId?: string;
    projectVersion?: string;
    projectChannel?: string;
    progress?: number;
    productOwnerId?: string;

    constructor(obj: Partial<Project>) {
        super(obj);
        Object.assign(this, obj);
    }
}

export class ProjectHolder {
    id?: string;
    projectName!: string;
    projectId?: string;

    constructor(obj: Partial<ProjectHolder>) {
        Object.assign(this, obj);
    }
}
