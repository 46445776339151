import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TaskApiService } from '../../services/task-api.service';
import { ChecklistApiService } from '../../services/checklist-api.service';
import { addChecklists, addChecklistsResolved, getChecklists, getChecklistsResolved, removeChecklists, removeChecklistsResolved, updateChecklists, updateChecklistsResolved } from './checklist.actions';

@Injectable({ providedIn: 'root' })
export class ChecklistEffects {
  constructor(
    private actions$: Actions,
    private checklistApiService: ChecklistApiService,
    private taskApiService: TaskApiService
  ) {}

  getChecklists = createEffect(() =>
    this.actions$.pipe(
      ofType(getChecklists),
      mergeMap(({ orderBy, direction, filters, callback }) =>
        this.taskApiService.getChecklists(orderBy, direction, filters).pipe(
          tap((checklists) =>
            callback ? callback(checklists) : undefined
          ),
          mergeMap((checklists) =>
            of(
              getChecklistsResolved({
                checklists,
              })
            )
          ),
          catchError((e) => [])
        )
      )
    )
  );

  addChecklists = createEffect(() =>
    this.actions$.pipe(
      ofType(addChecklists),
      switchMap(({ checklists, callback, error }) =>
        this.checklistApiService.addChecklists(checklists).pipe(
          tap((checklists) =>
            callback ? callback(checklists) : undefined
          ),
          switchMap((addedChecklists) =>
            of(addChecklistsResolved({ addedChecklists }))
          ),
          catchError((e) => {
            error?.(e);
            return [];
          })
        )
      )
    )
  );

  updateChecklists = createEffect(() =>
    this.actions$.pipe(
      ofType(updateChecklists),
      switchMap(({ checklists }) =>
        this.checklistApiService.updateChecklists(checklists).pipe(
          switchMap((updatedChecklists) =>
            of(
              updateChecklistsResolved({
                updatedChecklists,
              })
            )
          ),
          catchError((e) => [])
        )
      )
    )
  );

  removeChecklists = createEffect(() =>
    this.actions$.pipe(
      ofType(removeChecklists),
      mergeMap(({ id, callback }) =>
        this.checklistApiService.removeChecklists(id)
          .pipe(
            tap(() => (callback ? callback() : undefined)),
            mergeMap((id) =>
              of(removeChecklistsResolved({ id }))
            ),
            catchError((e) => [])
          )
      )
    )
  );
}
