import { Action, createReducer, on } from '@ngrx/store';
import { TimeRegistration } from '../../domain/models/time/time-registration.model';
import {
  addTimeRegistrationResolved,
  deleteTimeRegistrationResolved,
  updateTimeRegistrationResolved,
  getTimeRegistrationsResolved,
} from './time.actions';
import { AppState } from 'src/app/app.reducer';

export const featureSlice = 'timeRegistration';

export interface State {
  timeRegistrations: TimeRegistration[];
  timeRegistration?: TimeRegistration;
  id?: string;
}
const defaultState: State = {
  timeRegistrations: [],
  timeRegistration: undefined,
  id: undefined,
};

export const initialState: State = defaultState;

const timeRegistrationReducer = createReducer(
  initialState,
  on(getTimeRegistrationsResolved, (state, { timeRegistrations }) => ({
    ...state,
    timeRegistrations: [...timeRegistrations],
  })),
  on(addTimeRegistrationResolved, (state, { addedTimeRegistration }) => ({
    ...state,
    timeRegistrations: [addedTimeRegistration].concat([...state.timeRegistrations]),
  })),
  on(deleteTimeRegistrationResolved, (state, { id }) => ({
    ...state,
    timeRegistrations: state.timeRegistrations.filter(
      (t) => t.id != id
    ),
  })),
  on(updateTimeRegistrationResolved, (state, { updatedTimeRegistration }) => ({
    ...state,
    timeRegistrations: state.timeRegistrations.map((t) =>
      t.id == updatedTimeRegistration.id
        ? updatedTimeRegistration
        : t
    ),
  }))
);

export function Reducer(state: State | undefined, action: Action) {
  return timeRegistrationReducer(state, action);
}

export const TimeRegistrationState = (state: AppState) =>
  state.coreFeature.timeRegistration;
