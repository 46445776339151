import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { addBatchResolved, deleteBatchResolved, getBatchesResolved, updateBatchResolved } from './batch.actions';
import { Batch } from '../../domain/models/task/batch.model';

export const featureSlice = 'batch';

export interface State {
  batches: Batch[];
  batch?: Batch;
  id?: string;
  pagingCookie: string;
  totalRecordCount: number;
}
const defaultState: State = {
  batches: [],
  batch: undefined,
  id: undefined,
  pagingCookie: '',
  totalRecordCount: 0,
};

export const initialState: State = defaultState;

const batchReducer = createReducer(
  initialState,
  on(
    getBatchesResolved,
    (state, { result, pagingCookie, totalRecordCount, resetPaging }) => ({
      ...state,
      batches: resetPaging ? [...result] : [...state.batches, ...result],
      pagingCookie,
      totalRecordCount,
    })
  ),
  on(addBatchResolved, (state, { addedBatch }) => ({
    ...state,
    batches: [...state.batches, addedBatch],
  })),
  on(deleteBatchResolved, (state, { id }) => ({
    ...state,
    batches: state.batches.filter((b) => b.id !== id),
  })),
  on(updateBatchResolved, (state, { batch }) => ({
    ...state,
    batches: state.batches.map((b) => (b.id === batch.id ? batch : b)),
  }))
);

export function Reducer(state: State | undefined, action: Action) {
  return batchReducer(state, action);
}

export const batchState = (state: AppState) => state.coreFeature.batch;
