export class Kanban {
    id!: string;
    name!: string;
    number!: string;
    statusId?: string;
    //status?: Status;

    constructor(obj: Partial<Kanban>) {
        Object.assign(this, obj);
    }
}
