import { Pipe, PipeTransform } from '@angular/core';
import { ApprovalStatus } from '../../core/domain/enums/approval-status.enum';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
 */
@Pipe({ name: 'ApprovalStatusPipe', standalone: true })
export class ApprovalStatusPipe implements PipeTransform {
  static transform(value: ApprovalStatus | undefined | null): string {
    switch (value) {
      case ApprovalStatus.No:
        return 'Rejected';
      case ApprovalStatus.Yes:
        return 'Approved';
      case ApprovalStatus.Pending:
      default:
        return 'Pending';
    }
  }
  transform(
    value: ApprovalStatus | undefined | null,
    exponent = 1
  ): string {
    return ApprovalStatusPipe.transform(value);
  }
}
