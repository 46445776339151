import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  GroupUser,
  Ishtar365CommunicationService,
  LoaderService,
  MicrosoftAuthenticationService,
  TranslationService,
} from 'processdelight-angular-components';
import {
  Observable,
  Subject,
  catchError,
  filter,
  first,
  forkJoin,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import {
  actionTypes$,
  approvalTypes$,
  channelTypes$,
  days$,
  dependencyTypes$,
  frequencies$,
  groups$,
  licenseInfo$,
  metadataParameters$,
  months$,
  projects$,
  rankings$,
  registrationTypes$,
  resourceThings$,
  resourceUsers$,
  skills$,
  logTypes$,
  taskTypes$,
  license$,
  triggerTypes$,
  users$,
  statusTypes$,
} from '../data/data.observables';
import { Project, ProjectHolder } from '../domain/models/task/project.model';
import { LicenseInfo } from '../domain/models/user/license-info.model';
import { TaskFacade } from '../store/task/task.facade';
import { TypesApiService } from './types-api.service';
import { SessionService } from './session.service';
import { TaskApiService } from './task-api.service';
import { ErrorHandlingService } from '../helpers/error-handling.service';

export const appName = 'Ishtar.Tasks';
export const initialLoadSubject = new Subject<void>();
export const typesSubject = new Subject<void>();

@Injectable({
  providedIn: 'root',
})
export class StartUpService implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private taskApiService: TaskApiService,
    private typesApiService: TypesApiService,
    private sessionService: SessionService,
    private comService: Ishtar365CommunicationService,
    private router: Router,
    private loader: LoaderService,
    private translationService: TranslationService,
    private taskFacade: TaskFacade,
    private errorService: ErrorHandlingService,
    private activatedRoute: ActivatedRoute,
    private msal: MicrosoftAuthenticationService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getLicense(): Observable<LicenseInfo> {
    return this.sessionService.getLicense(this.msal.tenantId).pipe(
      takeUntil(this.destroy$),
      tap((data) => {
        licenseInfo$.next(data);
        license$.next(
          data.licenses.find((l) => l.productName === 'Ishtar.Tasks')
        );
      })
    );
  }

  getInitialLoad(): Observable<{ users?: GroupUser[], groups?: GroupUser[], translations?: any[] }> {
    return forkJoin([
      this.taskApiService.getTaskTranslations(),
      this.taskApiService.getTaskUsersAndGroups(),
    ]).pipe(
      takeUntil(this.destroy$),
      first(),
      tap(([translations, usersAndGroups]) => {
        users$.next(usersAndGroups.users?.map((u) => new GroupUser(u)));
        groups$.next(usersAndGroups.groups?.map((g) => new GroupUser(g)));
        this.translationService.update(translations || {});

        initialLoadSubject.next();
        initialLoadSubject.complete();
      }),
      catchError((error): Observable<any> => {
        this.errorService.handleError(error);
        initialLoadSubject.next();
        initialLoadSubject.complete();
        return of(null);
      })
    );
  }

  getAllTasks(): void {
    this.taskFacade.getAllTasks().subscribe();
  }

  getTemplateTasks(): void {
    this.taskFacade.getTemplateTasks().subscribe();
  }

  boot(): Observable<{ users?: GroupUser[], groups?: GroupUser[], translations?: any[] }> {
    timer(15 * 60 * 1000, 15 * 60 * 1000)
      .pipe(
        takeUntil(this.destroy$),
        filter(() => this.msal.signedIn.value),
        switchMap(() => this.sessionService.sessionKeepAlive())
      )
      .subscribe();

    this.loader.startLoading(
      'Loading initial data...',
      () => initialLoadSubject
    );

    return this.getLicense().pipe(
      takeUntil(this.destroy$),
      filter((license) => !!license),
      first(),
      switchMap((license) => {
        if (!license.licenses.some((l) => l.productName == 'Ishtar.Tasks')) {
          initialLoadSubject.next();
          initialLoadSubject.complete();
          this.router.navigate(['401']);
          throw new Error('No license for Ishtar.Tasks');
        }
        this.getTypes();
        this.getAllTasks();
        this.getTemplateTasks();

        document.documentElement.style.setProperty(
          '--nav-color',
          license?.navColor ?? '#fff'
        );
        document.documentElement.style.setProperty(
          '--nav-contrast',
          license?.navContrast ?? '#000'
        );

        try {
          this.comService.init();
          this.comService.registerRedirectAction('openTask', (id?: string) => {
            const segments = this.activatedRoute.snapshot.url.map(
              (s) => s.path
            );
            if (segments[0] !== 'tasks')
              this.router.navigate(['tasks'], { queryParams: { task: id } });
          });
          this.comService.registerRedirectActions({
            tasksFilteredByProject: (id) => {
              this.router.navigate(['tasks'], {
                queryParams: { projectId: id },
              });
            },
          });
          this.comService.registerRedirectAction(
            'addTaskForProject',
            (id?: string) => {
              const segments = this.activatedRoute.snapshot.url.map(
                (s) => s.path
              );
              if (segments[0] !== 'tasks')
                this.router.navigate(['tasks'], { queryParams: { task: id } });
            }
          );
          this.comService.registerRedirectAction(
            'ProjectAdded',
            (id?: string, data?: unknown) => {
              const project = data as Project;
              const projectHolder = new ProjectHolder({
                id: project.id,
                projectName: project.projectName,
                projectId: project.projectId,
              });
              projects$.next([...(projects$.value ?? []), projectHolder]);
            }
          );
          this.comService.registerRedirectAction(
            'resourcesAddedToTask',
            (id?: string, data?: unknown) => {
              const segments = this.activatedRoute.snapshot.url.map(
                (s) => s.path
              );
              if (segments[0] !== 'tasks')
                this.router.navigate(['tasks'], { queryParams: { task: id } });
            }
          );
        } catch (error) {
          console.log('Cannot reroute, Not in Ishtar365', error);
        }

        return this.getInitialLoad();
      })
    );
  }

  private getTypes(): void {
    this.typesApiService
      .getSkills()
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        tap((type) => skills$.next(type)),
        catchError((error) => {
          this.errorService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
    this.taskFacade
      .getStatuses()
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        catchError((error) => {
          this.errorService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
    this.typesApiService
      .getTaskTypes()
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        tap((type) => taskTypes$.next(type)),
        catchError((error) => {
          this.errorService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
    this.typesApiService
      .getRegistrationTypes()
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        tap((type) => registrationTypes$.next(type)),
        catchError((error) => {
          this.errorService.handleError(error);

          // retry once if error
          return this.typesApiService.getRegistrationTypes().pipe(
            take(1),
            takeUntil(this.destroy$),
            tap((type) => registrationTypes$.next(type)),
            catchError((error) => {
              this.errorService.handleError(error);
              return of(null);
            })
          );
        })
      )
      .subscribe();
    this.typesApiService
      .getProjects()
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        tap((type) => projects$.next(type)),
        catchError((error) => {
          this.errorService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
    this.typesApiService
      .getDependencyTypes()
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        tap((type) => dependencyTypes$.next(type)),
        catchError((error) => {
          this.errorService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
    this.typesApiService
      .getActionTypes()
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        tap((type) => actionTypes$.next(type)),
        catchError((error) => {
          this.errorService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
    this.typesApiService
      .getLogTypes()
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        tap((type) => logTypes$.next(type)),
        catchError((error) => {
          this.errorService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
    this.typesApiService
      .getApprovalTypes()
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        tap((type) => approvalTypes$.next(type)),
        catchError((error) => {
          this.errorService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
    this.typesApiService
      .getTriggerTypes()
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        tap((type) => triggerTypes$.next(type)),
        catchError((error) => {
          this.errorService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
    this.typesApiService
      .getChannelTypes()
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        tap((type) => channelTypes$.next(type)),
        catchError((error) => {
          this.errorService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
    this.typesApiService
      .getStatusTypes()
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        tap((type) => statusTypes$.next(type)),
        catchError((error) => {
          this.errorService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
    this.typesApiService
      .getMetadataParameters()
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        tap((type) => metadataParameters$.next(type)),
        catchError((error) => {
          this.errorService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
    this.typesApiService
      .getDays()
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        tap((type) => days$.next(type)),
        catchError((error) => {
          this.errorService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
    this.typesApiService
      .getMonths()
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        tap((type) => months$.next(type)),
        catchError((error) => {
          this.errorService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
    this.typesApiService
      .getRankings()
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        tap((type) => rankings$.next(type)),
        catchError((error) => {
          this.errorService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
    this.typesApiService
      .getFrequencies()
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        tap((type) => frequencies$.next(type)),
        catchError((error) => {
          this.errorService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
    this.typesApiService
      .getResourceThings()
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        tap((type) => resourceThings$.next(type)),
        catchError((error) => {
          this.errorService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
    this.typesApiService
      .getResourceUsers()
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        tap((type) => resourceUsers$.next(type)),
        catchError((error) => {
          this.errorService.handleError(error);
          return of(null);
        })
      )
      .subscribe();
  }
}
