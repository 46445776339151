
export class Attendee {
  userId!: string;
  taskId?: string;
  externalUserMail?: string;
  response?: ResponseType;

  constructor(obj: Partial<Attendee>) {
    Object.assign(this, obj);
  }
}
