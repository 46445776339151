import { DateTime } from 'luxon';
import { BaseEntity } from 'processdelight-angular-components';

export class TimeRegistration extends BaseEntity {
  title!: string;
  startDate?: DateTime;
  endDate?: DateTime;
  taskId?: string;
  skillId?: string;
  projectId?: string;
  userId?: string;
  timeSortId?: string;

  constructor(obj: Partial<TimeRegistration>) {
    super(obj);
    Object.assign(this, obj);
  }
}
