import { BaseEntity } from "processdelight-angular-components";
import { ApprovalStatus } from "../../../enums/approval-status.enum";
import { ApprovalFlowComment } from "./approval-flow-comment.model";
import { ApprovalFlowGroup } from "./approval-flow-group.model";
import { DateTime } from "luxon";

export class ApprovalFlow extends BaseEntity {
    parentFlowId?: string;
    taskId?: string;
    roundTitle?: string;
    isApproved!: ApprovalStatus;
    startTime?: DateTime;
    timingInSeconds?: number;
    isLocked = false;
    comments?: ApprovalFlowComment[];
    approvalFlowGroups?: ApprovalFlowGroup[];

    constructor(obj: Partial<ApprovalFlow>) {
        super(obj);
        Object.assign(this, obj);
    }
}
