import { createAction, props } from "@ngrx/store";
import { TimeRegistration } from "../../domain/models/time/time-registration.model";
import { Filter } from "processdelight-angular-components";

export const getTimeRegistrations = createAction(
    "[TimeRegistration] Get TimeRegistations",
    props<{
        filters: Filter[];
        callback?: () => void;
    }>()
);

export const getTimeRegistrationsResolved = createAction(
    "[TimeRegistation] Get TimeRegistations resolved",
    props<{
        timeRegistrations: TimeRegistration[];
    }>()
);

export const deleteTimeRegistration = createAction(
    "[TimeRegistration] Delete TimeRegistration",
    props<{
        id: string;
        callback?: () => void;
    }>()
);

export const deleteTimeRegistrationResolved = createAction(
    "[TimeRegistration] Delete TimeRegistration resolved",
    props<{
        id: string;
    }>()
);

export const updateTimeRegistration = createAction(
    "[TimeRegistration] Update TimeRegistration",
    props<{
        timeRegistration: TimeRegistration;
        callback?: (timeRegistration: TimeRegistration) => void;
    }>()
);

export const updateTimeRegistrationResolved = createAction(
    "[TimeRegistration] Update TimeRegistration resolved",
    props<{
        updatedTimeRegistration: TimeRegistration;
    }>()
);

export const addTimeRegistration = createAction(
    "[TimeRegistration] Add TimeRegistration",
    props<{
        timeRegistration: TimeRegistration;
        callback?: (addedTimeRegistration: TimeRegistration) => void;
    }>()
);

export const addTimeRegistrationResolved = createAction(
    "[TimeRegistration] Add TimeRegistration resolved",
    props<{
        addedTimeRegistration: TimeRegistration;
    }>()
);

// export const deleteTimeRegistation = createAction(
//     "[TimeRegistation] Delete TimeRegistation",
//     props<{ ids: string[]; callback?: () => void }>()
// );

// export const deleteTimeRegistationResolved = createAction(
//     "[TimeRegistation] Delete TimeRegistation resolved",
//     props<{ TimeRegistrationIds: string[] }>()
// );

// export const updateTimeRegistation = createAction(
//     "[TimeRegistation] Update TimeRegistation",
//     props<{ TimeRegistation: TimeRegistrationRegistration[]; callback?: () => void }>()
// );

// export const updateTimeRegistationResolved = createAction(
//     "[TimeRegistation] Update TimeRegistation resolved",
//     props<{ updatedTimeRegistation: TimeRegistrationRegistration[] }>()
// );

// export const addTimeRegistation = createAction(
//     "[TimeRegistation] Add TimeRegistation",
//     props<{
//         TimeRegistation: TimeRegistrationRegistration[];
//         callback?: (addedTimeRegistation: TimeRegistrationRegistration[]) => void;
//     }>()
// );

// export const addTimeRegistationResolved = createAction(
//     "[TimeRegistation] Add TimeRegistation resolved",
//     props<{ addedTimeRegistation: TimeRegistrationRegistration[] }>()
// );
