import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { Batch } from '../domain/models/task/batch.model';
import { DashboardComponent, Filter } from 'processdelight-angular-components';
import { camelcaseKeys } from '../helpers/object.functions';

@Injectable({ providedIn: 'root' })
export class BatchApiService {
  apiBase = `${location.origin}/web`;

  constructor(
    private httpClient: HttpClient
  ) {}

  /////////////////////////////////////////////////// GET ///////////////////////////////////////////////////
  public getBatches(   
    orderBy: string,
    direction: string,
    filters: Filter[],
    pageSize: number,
    page: number
  ): Observable<{ result: Batch[]; pagingCookie: string; totalRecordCount: number; }> {
    const filter = DashboardComponent.createFilterString(filters);
    let url = `${this.apiBase}/batch?orderBy=${orderBy}&direction=${direction}&pageSize=${pageSize}&page=${page}`;

    if (filter !== '') {
      url += `&filter=${filter}`;
    }
    
    return this.httpClient
      .get<{ result: Batch[]; pagingCookie: string; totalRecordCount: number; }>(url)
      .pipe(
        map(({ result, pagingCookie, totalRecordCount }) => ({
          result: result.map((t) => new Batch(camelcaseKeys(t))),
          pagingCookie,
          totalRecordCount,
        })),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////////// POST ///////////////////////////////////////////////////
  public addBatch(batch: Batch): Observable<Batch> {
    return this.httpClient.post<Batch>(`${this.apiBase}/batch`, batch)
      .pipe(
        map((batch) => new Batch(camelcaseKeys(batch))),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////////// PATCH ///////////////////////////////////////////////////
  public updateBatch(batch: Batch): Observable<Batch> {
    return this.httpClient.patch<Batch>(`${this.apiBase}/batch`, batch)
      .pipe(
        map((batch) => new Batch(camelcaseKeys(batch))),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /////////////////////////////////////////////////// DELETE ///////////////////////////////////////////////////
  public removeBatch(batchId: string): Observable<string> {
    return this.httpClient.delete(`${this.apiBase}/batch/${batchId}`, { responseType: 'text' })
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
