import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DataComponentFacade,
  DataSourceService,
  DataSourceType,
  EntityFacade,
  GroupUser,
  Ishtar365CommunicationService,
  Library,
  LibraryFacade,
  LoaderService,
  MetadataFacade,
  MetadataParam,
  MicrosoftAuthenticationService,
  StateEntity,
  Task,
  TranslationService,
} from 'processdelight-angular-components';
import {
  Observable,
  Subject,
  catchError,
  combineLatest,
  filter,
  first,
  firstValueFrom,
  forkJoin,
  map,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import {
  approvalTypes$,
  days$,
  dependencyTypes$,
  frequencies$,
  groups$,
  licenseInfo$,
  metadataParameters$,
  months$,
  projects$,
  rankings$,
  registrationTypes$,
  resourceThings$,
  skills$,
  logTypes$,
  taskTypes$,
  license$,
  users$,
  statusTypes$,
  currentApplication$,
  countries$,
  varlanguages$,
  action$,
  allTasks$,
  statuses$,
  resourceUsers$,
  allUsers$,
} from '../data/data.observables';
import { Project, ProjectHolder } from '../domain/models/task/project.model';
import { LicenseInfo } from '../domain/models/user/license-info.model';
import { TaskFacade } from '../store/task/task.facade';
import { TypesApiService } from './types-api.service';
import { SessionService } from './session.service';
import { TaskApiService } from './task-api.service';
import { TaskDataComponentService } from './task-datacomponent.service';
import { ErrorHandlingService } from '../helpers/error-handling.service';
import { StatusApiService } from './status-api.service';
import { getStatusesResolved } from '../store/task/task.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';

export const appName = 'Ishtar.Tasks';
export const initialLoadSubject = new Subject<void>();
export const typesSubject = new Subject<void>();

@Injectable({
  providedIn: 'root',
})
export class StartUpService implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private taskApiService: TaskApiService,
    private typesApiService: TypesApiService,
    private sessionService: SessionService,
    private comService: Ishtar365CommunicationService,
    private router: Router,
    private loader: LoaderService,
    private translationService: TranslationService,
    private taskFacade: TaskFacade,
    private errorService: ErrorHandlingService,
    private activatedRoute: ActivatedRoute,
    private msal: MicrosoftAuthenticationService,
    private taskDataComponentService: TaskDataComponentService,
    private dataPropertyFacade: DataComponentFacade,
    private dataSourceService: DataSourceService,
    private statusApiService: StatusApiService,
    private entityFacade: EntityFacade,
    private libraryFacade: LibraryFacade,
    private metadataFacade: MetadataFacade,
    private _store: Store<AppState>
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getLicense(): Observable<LicenseInfo> {
    return this.sessionService.getLicense(this.msal.tenantId).pipe(
      takeUntil(this.destroy$),
      tap((data) =>
        this.dataSourceService.updateData(DataSourceType.LICENSES, data)
      ),
      tap((data) => {
        licenseInfo$.next(data);
        license$.next(
          data.licenses.find((l) => l.productName === 'Ishtar.Tasks')
        );
      })
    );
  }

  getInitialLoad(): Observable<{
    users?: GroupUser[];
    groups?: GroupUser[];
    translations?: any[];
  }> {
    return forkJoin([
      this.taskApiService.getTaskTranslations(),
      this.taskApiService.getTaskUsersAndGroups(),
    ]).pipe(
      takeUntil(this.destroy$),
      first(),
      tap(([translations, usersAndGroups]) => {
        allUsers$.next(usersAndGroups.users?.map((u) => new GroupUser(u)));
        users$.next(usersAndGroups.licensedUsers?.map((u) => new GroupUser(u)));
        groups$.next(usersAndGroups.groups?.map((g) => new GroupUser(g)));
        this.translationService.update(translations || {});

        this.dataSourceService.updateDataWithObservable(
          'peopleAndGroups',
          combineLatest([groups$, users$]).pipe(
            map(([groups, users]) => (groups ?? []).concat(users ?? []))
          )
        );

        this.dataSourceService.updateDataWithObservable('people', users$);

        this.dataSourceService.updateDataWithObservable('groups', groups$);

        this.dataSourceService.updateDataWithObservable(
          'relationFieldTables',
          this.taskDataComponentService.getRelationFieldTables()
        );

        initialLoadSubject.next();
        initialLoadSubject.complete();
      }),
      catchError((error): Observable<any> => {
        this.errorService.handleError(error);
        initialLoadSubject.next();
        initialLoadSubject.complete();
        return of(null);
      })
    );
  }

  getAllTasks(): void {
    this.taskFacade.getAllTasks().subscribe((data) => allTasks$.next(data));
  }

  // getTemplateTasks(): void {
  //   this.taskFacade.getTemplateTasks().subscribe();
  // }

  getPropertyTypes() {
    return this.dataPropertyFacade.getPropertyTypes$();
  }

  getDataProperties() {
    return this.dataPropertyFacade.getDataProperties$();
  }

  getPropertyRights() {
    return this.dataPropertyFacade.getPropertyRights$();
  }

  getStatusses() {
    return this.taskFacade
      .getStatuses()
      .pipe(tap((statuses) => statuses$.next(statuses)));
  }

  getCurrentApplication() {
    return this.taskDataComponentService
      .getCurrentApplication()
      .pipe(tap((data) => currentApplication$.next(data)));
  }

  getInterestGroups() {
    return this.taskDataComponentService
      .getInterestGroups()
      .pipe(
        tap((data) => this.dataSourceService.updateData('interestGroups', data))
      );
  }

  getFormTemplates() {
    return this.entityFacade.getFormTemplates$<Task>(StateEntity.Task);
  }

  private getAdminLibraries(): Observable<Library[]> {
    return this.libraryFacade
      .getAdminLibraries$()
      .pipe(
        tap((data) =>
          this.dataSourceService.updateData(DataSourceType.LIBRARIES_ALL, data)
        )
      );
  }

  private getLibrariesByPermission(): Observable<Library[]> {
    return this.libraryFacade
      .getLibraries$()
      .pipe(
        tap((data) =>
          this.dataSourceService.updateData(
            DataSourceType.LIBRARIES_BY_PERMISSION,
            data
          )
        )
      );
  }

  private getAdminMetadataParams(): Observable<MetadataParam[]> {
    return this.metadataFacade
      .getAdminMetadataParams$()
      .pipe(
        tap((data) =>
          this.dataSourceService.updateData(DataSourceType.METADATA_ALL, data)
        )
      );
  }

  private getMetadataByPermission(): Observable<MetadataParam[]> {
    return this.metadataFacade
      .getMetadataParams$()
      .pipe(
        tap((data) =>
          this.dataSourceService.updateData(
            DataSourceType.METADATA_BY_PERMISSION,
            data
          )
        )
      );
  }

  boot(): Observable<{
    users?: GroupUser[];
    groups?: GroupUser[];
    translations?: any[];
  }> {
    timer(15 * 60 * 1000, 15 * 60 * 1000)
      .pipe(
        takeUntil(this.destroy$),
        filter(() => this.msal.signedIn.value),
        switchMap(() => this.sessionService.sessionKeepAlive())
      )
      .subscribe();

    this.loader.startLoading(
      'Loading initial data...',
      () => initialLoadSubject
    );

    return this.getLicense().pipe(
      takeUntil(this.destroy$),
      filter((license) => !!license),
      first(),
      switchMap((license) => {
        if (!license.licenses.some((l) => l.productName == 'Ishtar.Tasks')) {
          initialLoadSubject.next();
          initialLoadSubject.complete();
          this.router.navigate(['401']);
          throw new Error('No license for Ishtar.Tasks');
        }

        const isDMSAdmin = license.licenses.some(
          (l) => l.productName == 'Ishtar.DMS' && l.isAdmin
        );
        this.dataSourceService.updateData(
          DataSourceType.IS_DMS_ADMIN,
          isDMSAdmin
        );

        this.getTypes();
        this.getAllTasks();
        this.getPropertyTypes().subscribe();
        this.getDataProperties().subscribe();
        this.getPropertyRights().subscribe();
        this.getCurrentApplication().subscribe();
        this.getFormTemplates().subscribe();
        this.getInterestGroups().subscribe();

        void firstValueFrom(this.getLibrariesByPermission());
        void firstValueFrom(this.getMetadataByPermission());
        void firstValueFrom(isDMSAdmin ? this.getAdminLibraries() : of([]));
        void firstValueFrom(
          isDMSAdmin ? this.getAdminMetadataParams() : of([])
        );

        document.documentElement.style.setProperty(
          '--nav-color',
          license?.navColor ?? '#fff'
        );
        document.documentElement.style.setProperty(
          '--nav-contrast',
          license?.navContrast ?? '#000'
        );

        try {
          this.comService.init();
          this.comService.registerRedirectActions({
            tasksFilteredByProject: (id) => {
              this.router.navigate(['tasks'], {
                queryParams: { projectId: id },
              });
            },
          });
          this.comService.registerRedirectAction('openTask', (id?: string) => {
            const segments = this.activatedRoute.snapshot.url.map(
              (s) => s.path
            );
            if (segments[0] !== 'tasks')
              this.router.navigate(['tasks'], { queryParams: { task: id } });
          });
          this.comService.registerRedirectAction(
            'addTaskForProject',
            (id?: string) => {
              const segments = this.activatedRoute.snapshot.url.map(
                (s) => s.path
              );
              if (segments[0] !== 'tasks')
                this.router.navigate(['tasks'], { queryParams: { task: id } });
            }
          );
          this.comService.registerRedirectAction(
            'ProjectAdded',
            (id?: string, data?: unknown) => {
              const project = data as Project;
              const projectHolder = new ProjectHolder({
                id: project.id,
                projectName: project.projectName,
                projectId: project.projectId,
              });
              projects$.next([...(projects$.value ?? []), projectHolder]);
            }
          );
          this.comService.registerRedirectAction(
            'resourcesAddedToTask',
            (id?: string, data?: unknown) => {
              const segments = this.activatedRoute.snapshot.url.map(
                (s) => s.path
              );
              if (segments[0] !== 'tasks')
                this.router.navigate(['tasks'], { queryParams: { task: id } });
            }
          );
          this.comService.registerRedirectAction(
            'ShortCut',
            (id?: string, data?: any) => {
              if (data) {
                action$.next(data);
                this.router.navigate(['tasks'], {
                  queryParams: { shortCut: true },
                });
              }
            }
          );
          this.comService.registerRedirectAction(
            'formTemplate',
            (id?: string, data?: any) => {
              if (data && data.template) {
                this.router.navigate(['tasks'], {
                  queryParams: { template: data.template },
                });
              }
            }
          );
        } catch (error) {
          console.log('Cannot reroute, Not in Ishtar365', error);
        }

        return this.getInitialLoad();
      })
    );
  }

  private getTypes(): void {
    this.typesApiService
      .getStartUpData()
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        catchError((error) => {
          this.errorService.handleError(error);
          return of(null);
        })
      )
      .subscribe((contract) => {
        if (!contract) return;
        skills$.next(contract.skills ? contract.skills : []);
        taskTypes$.next(contract.taskTypes ? contract.taskTypes : []);
        registrationTypes$.next(
          contract.registrationTypes ? contract.registrationTypes : []
        );
        dependencyTypes$.next(
          contract.dependencyTypes ? contract.dependencyTypes : []
        );
        logTypes$.next(contract.logTypes ? contract.logTypes : []);
        approvalTypes$.next(
          contract.approvalTypes ? contract.approvalTypes : []
        );
        statusTypes$.next(contract.statusTypes ? contract.statusTypes : []);
        metadataParameters$.next(
          contract.metadataParameters ? contract.metadataParameters : []
        );
        days$.next(contract.days ? contract.days : []);
        months$.next(contract.months ? contract.months : []);
        rankings$.next(contract.rankings ? contract.rankings : []);
        frequencies$.next(contract.frequencies ? contract.frequencies : []);
        resourceThings$.next(
          contract.resourceThings ? contract.resourceThings : []
        );
        resourceUsers$.next(
          contract.resourceUsers ? contract.resourceUsers : []
        );
        projects$.next(contract.projects ? contract.projects : []);
        if (contract.countries) {
          countries$.next(contract.countries);
          this.dataSourceService.updateData('countries', contract.countries);
        } else {
          countries$.next([]);
        }
        if (contract.languages) {
          varlanguages$.next(contract.languages);
          this.dataSourceService.updateData('languages', contract.languages);
        }
        if (contract.statusses) {
          statuses$.next(contract.statusses);
          this._store.dispatch(
            getStatusesResolved({
              statuses: contract.statusses,
            })
          );
        }
      });
  }
}
